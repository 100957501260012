import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../components/lazy-image';
import ButtonWithCircle from '../components/ButtonWithCircle';
import urlParser from '../utils/urlParser';
import linkStripper from '../utils/link-stripper';
import Label from '../components/Label';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import ButtonUnderlined from '../components/ButtonUnderlined';

const CaseStudyHeaderTwoContainer = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

const CaseStudyHeaderTwoContainerMobile = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
    padding-top: ${props => props.height};
  }
`;

const BlockContainer = styled.div`
  display: flex;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const BlockOne = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #848ee0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
`;

const BlockOneOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    justify-content: flex-start;
  }
`;

const BlockOneInner = styled.div`
  max-width: 1000px;
  width: 100%;
  padding-left: 50px;

  @media (max-width: 800px) {
    padding-left: 25px;
  }
`;

const BlockTwoOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const BlockTwoInner = styled.div`
  max-width: 1000px;
  width: 100%;
  padding-left: 50px;
  @media (max-width: 800px) {
    padding-left: 0px;
  }
`;

const BlockTwo = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #848ee0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    padding-top: 50px;
    padding-bottom: 50px;
    height: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  height: 100vh;
  position: webkit-sticky;
  position: sticky;
  top: 0px;
  width: 50vw;
  @media (max-width: 800px) {
    width: 100%;
    height: 50vh;
    position: initial;
  }
`;

const TagContainer = styled.div`
  font-family: DM Sans;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #817e7e;
  @media (max-width: 800px) {
    display: none;
    font-size: 11px;
    line-height: 20px;
  }
`;

const LabelContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const LabelContainerMobile = styled.div`
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    display: block;
  }
`;

const Title = styled.h1`
  font-family: DM Serif Text;
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 65px;
  color: #404040;
  color: #404040;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-right: 50px;

  @media (max-width: 1250px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 985px) {
    padding-right: 20px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 800px) {
    max-width: 90%;
    font-size: 35px;
    line-height: 35px;
  }
  @media (max-width: 600px) {
    font-size: 35px;
    line-height: 35px;
  }
  @media (max-height: 600px) {
    font-size: 35px;
    line-height: 35px;
  }
`;

const Paragraph = styled.div`
  max-width: 456px;
  font-family: DM Sans;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #404040;
  margin-bottom: 40px;
  @media (max-width: 800px) {
    max-width: 90%;
    font-size: 14px;
    line-height: 18px;
  }
  @media (max-width: 600px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    width: unset;
    margin-bottom: 20px;
  }
`;

const CtaContainer = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`;

const CtaContainerMobile = styled.div`
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

const BriefTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #404040;
  margin-bottom: 10px;
  max-width: 600px;
  font-family: DM Sans;
  font-style: normal;
  @media (max-width: 800px) {
    max-width: 90%;
  }
`;

const BriefSubtitle = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
  margin-bottom: 30px;
  max-width: 600px;
  @media (max-width: 800px) {
    max-width: 90%;
  }
`;

const BriefParagraphTitle = styled.h2`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #404040;
  margin-bottom: 10px;
  max-width: 600px;
  @media (max-width: 1300px) {
    padding-right: 50px;
  }
  @media (max-width: 800px) {
    font-size: 25px;
    line-height: 30px;
    max-width: 90%;
    padding-right: 0px;
  }
`;

const BriefParagraph = styled.div`
  font-family: DM Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #404040;
  margin-bottom: 10px;
  max-width: 600px;
  @media (max-width: 1300px) {
    padding-right: 50px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 24px;
    max-width: 100%;
    padding-right: 0px;
  }
`;

const BreifButtonContainer = styled.div`
  margin-top: 30px;
`;

export class CaseStudyHeaderTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <CaseStudyHeaderTwoContainer>
            <BlockContainer>
              <ContentContainer>
                <BlockOne>
                  <BlockOneOuter>
                    <BlockOneInner>
                      <TagContainer>{this.props.tags.text}</TagContainer>
                      {this.props.label_text.text && (
                        <LabelContainer>
                          <Label
                            text={this.props.label_text.text}
                            color={this.props.color_theme}
                            fontSize={'14px'}
                            fontColor={this.props.label_color}
                          />
                        </LabelContainer>
                      )}
                      {this.props.label_text.text && (
                        <LabelContainerMobile>
                          <Label
                            text={this.props.label_text.text}
                            color={this.props.label_text_color}
                            fontSize={'11px'}
                            fontColor={this.props.label_color}
                          />
                        </LabelContainerMobile>
                      )}
                      <Title>{this.props.title_text.text}</Title>
                      <Paragraph>{this.props.subtitle_text.text}</Paragraph>
                      <CtaContainer>
                        <ButtonNotUnderlined
                          link={linkStripper(this.props.cta_link.url)}
                          arrowColor={this.props.cta_arrow_color}
                          color={this.props.cta_text_color}
                          buttonText={this.props.cta_text.text}
                          target={this.props.cta_link.target}
                        />
                      </CtaContainer>
                      <CtaContainerMobile>
                        <ButtonNotUnderlined
                          arrowColor={this.props.color_theme}
                          color={this.props.color_theme}
                          buttonText={this.props.cta_text.text}
                          link={linkStripper(this.props.cta_link.url)}
                          target={this.props.cta_link.target}
                        />
                      </CtaContainerMobile>
                    </BlockOneInner>
                  </BlockOneOuter>
                </BlockOne>
                <BlockTwo>
                  <BlockTwoOuter>
                    <BlockTwoInner>
                      <BriefTitle>{this.props.the_brief_title.text}</BriefTitle>
                      <BriefSubtitle>
                        {this.props.the_brief_subtitle.text}
                      </BriefSubtitle>
                      <BriefParagraphTitle>
                        {this.props.brief_paragraph_title.text}
                      </BriefParagraphTitle>
                      <BriefParagraph>
                        {this.props.brief_paragraph.text}
                      </BriefParagraph>
                      <BreifButtonContainer>
                        <ButtonNotUnderlined
                          arrowColor={this.props.color_theme}
                          color={this.props.brief_cta_color}
                          buttonText={this.props.brief_cta_text.text}
                          link={linkStripper(this.props.brief_cta_link.url)}
                          target={this.props.brief_cta_link.target}
                        />
                      </BreifButtonContainer>
                    </BlockTwoInner>
                  </BlockTwoOuter>
                </BlockTwo>
              </ContentContainer>
              <ImageContainer id="stickyContainer">
                {this.props.header_image && (
                  <LazyImage {...this.props.header_image} />
                )}
              </ImageContainer>
            </BlockContainer>
          </CaseStudyHeaderTwoContainer>

          <CaseStudyHeaderTwoContainerMobile
            height={
              this.props.customData.prismicPage.data.display_review_bar
                ? '90px'
                : '150px'
            }
          >
            <React.Fragment>
              <BlockOne>
                <BlockOneOuter>
                  <BlockOneInner>
                    <TagContainer>{this.props.tags.text}</TagContainer>
                    {this.props.label_text.text && (
                      <LabelContainer>
                        <Label
                          text={this.props.label_text.text}
                          color={this.props.color_theme}
                          fontSize={'14px'}
                          fontColor={this.props.label_color}
                        />
                      </LabelContainer>
                    )}
                    {this.props.label_text.text && (
                      <LabelContainerMobile>
                        <Label
                          text={this.props.label_text.text}
                          color={this.props.label_text_color}
                          fontSize={'11px'}
                          fontColor={this.props.label_color}
                        />
                      </LabelContainerMobile>
                    )}
                    <Title>{this.props.title_text.text}</Title>
                    <Paragraph>{this.props.subtitle_text.text}</Paragraph>
                    <CtaContainer>
                      <ButtonNotUnderlined
                        link={linkStripper(this.props.cta_link.url)}
                        arrowColor={this.props.cta_arrow_color}
                        color={this.props.cta_text_color}
                        buttonText={this.props.cta_text.text}
                        target={this.props.cta_link.target}
                      />
                    </CtaContainer>
                    <CtaContainerMobile>
                      <ButtonNotUnderlined
                        arrowColor={this.props.color_theme}
                        color={this.props.color_theme}
                        buttonText={this.props.cta_text.text}
                        link={linkStripper(this.props.cta_link.url)}
                        target={this.props.cta_link.target}
                      />
                    </CtaContainerMobile>
                  </BlockOneInner>
                </BlockOneOuter>
              </BlockOne>
              <ImageContainer>
                {this.props.header_image && (
                  <LazyImage {...this.props.header_image} />
                )}
              </ImageContainer>
              <BlockTwo>
                <BlockTwoOuter>
                  <BlockTwoInner>
                    <BriefTitle>{this.props.the_brief_title.text}</BriefTitle>
                    <BriefSubtitle>
                      {this.props.the_brief_subtitle.text}
                    </BriefSubtitle>
                    <BriefParagraphTitle>
                      {this.props.brief_paragraph_title.text}
                    </BriefParagraphTitle>
                    <BriefParagraph>
                      {this.props.brief_paragraph.text}
                    </BriefParagraph>
                    <BreifButtonContainer>
                      <ButtonNotUnderlined
                        arrowColor={this.props.color_theme}
                        color={this.props.brief_cta_color}
                        buttonText={this.props.brief_cta_text.text}
                        link={linkStripper(this.props.brief_cta_link.url)}
                        target={this.props.brief_cta_link.target}
                      />
                    </BreifButtonContainer>
                  </BlockTwoInner>
                </BlockTwoOuter>
              </BlockTwo>
            </React.Fragment>
          </CaseStudyHeaderTwoContainerMobile>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default CaseStudyHeaderTwo;
